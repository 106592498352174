@import './config.scss';
#about{
	background-color: white;
	padding-bottom: 12rem;
	@media only screen and (max-width: 1024px){
		padding-bottom: 8em;
	}
	main{
		padding-top: 8em;
		padding-bottom: 4em;
		position: relative;
		z-index: 10;
		h3{
			font-size: 1.4rem;
			color: $grey;
			margin-bottom: 2rem;
		}
		p{
			width: calc(50% - 4rem);
			position: relative;
			z-index: 10;
			@media only screen and (max-width: 1024px){
				width: 100%;
				max-width: 20rem;
			}
		}
		p:first-of-type{
			line-height: 2em;
		}
		p:nth-of-type(2){
			margin-top: 5em;
			text-indent: 1em;
		}
		img{
			position: absolute;
			right: 8rem;
			top: 12.4rem;
			width: calc(40% - 4rem);
			max-width: 520px;
			z-index: 0;
			@media only screen and (min-width: 1580px){
				right: 12rem;
			}
			@media only screen and (max-width: 768px){
				right: 1rem;
				width: calc(100% - 24rem);
			}
			@media only screen and (max-width: 580px){
				display: none;
			}
		}
	}

	aside{
		background-color: $offWhite;
		padding-top:2rem;
		padding-bottom: 2rem;
		color: $grey;
		position: relative;
		z-index: 0;
		h3{
			font-size: 1.2rem;
			font-weight: 500;
			letter-spacing: .1em;
			margin-bottom: 1em;
		}
		table{
			font-size: .8rem;
			@media only screen and (max-width: 768px){
				font-size: .7rem;
			}
			th{
				text-align: right;
				padding-right: 2rem;
			}
			td{
				line-height: 1.6em;
				padding-bottom: .8rem;
			}
		}
		img{
			position: absolute;
			right: 4rem;
			top: -8rem;
			width: calc(60% - 4rem);
			max-width: 880px;
			@media only screen and (max-width: 1280px){
				width: 44%;
				top:-2rem;
			}
			@media only screen and (max-width: 1024px){
				display: none;
			}
		}
	}
}

