@import './config.scss';
.more{
	cursor: pointer;
	color: $orange;
	border: none;
	font-size: 1.2rem;
	letter-spacing: .07em;
	background: none;
	position: relative;
	padding-right: 1.4em;
	padding-left: 0;
	.icon{
		font-size: .8em;
		position: absolute;
		right: .5em;
		bottom:.4em;
	}
}