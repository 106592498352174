@import './config.scss';

#news_index button{
	font-family: 'Suez One', serif;
	border:none;
	background-color: $navy;
	font-size: 1em;
	border-radius: 4px;
	letter-spacing: .1em;
	color: $offWhite;
	margin-top: 1.4rem;
	position: relative;
	padding: 4px 1.4em 4px 8px;
	cursor: pointer;
	svg{
		font-size: .8em;
		position: absolute;
		right: .5em;
		bottom:.6em;
	}
}

#news_index{
	background-color: white;
	#page-header{
		position: relative;
		z-index: 10;
	}
	main{
		position: relative;
		z-index: 10;
		padding-top: 8rem;
		padding-right: 8rem;
		@media only screen and (min-width: 1580px){
			padding-right: 12rem;
		}
		@media only screen and (max-width: 1024px){
			padding-right: 2rem;
		}
		@media only screen and (max-width: 768px){
			padding-right: 1rem;
			padding-top: 4rem;
		}
		ul{
			li{
				display: table;
				margin-bottom: 4em;
				width: 100%;
				position: relative;
				@media only screen and (max-width: 768px){
					display: block;
				}
				time{
					vertical-align: top;
					display: table-cell;
					width: 12rem;
					@media only screen and (min-width: 1580px){
						width: 16rem;
					}
					text-align: right;
					padding-right: 1rem;
					box-sizing: border-box;
					font-size: .8em;
					font-weight: 500;
					color: $grey;
					@media only screen and (max-width: 768px){
						display: block;
						width: 100%;
						text-align: left;
						padding-left: 1rem;
						margin-bottom: .8rem;
						background-color: white;
					}
				}
				.right{
					display: table-cell;
					padding-left: 2rem;
					.content{
						width: calc(100% * 5 / 9 - 1em);
						float: left;
						h3{
							font-size: 1.6em;
							font-weight: 500;
							line-height: 1.4em;
							color: $navy;
							margin-bottom: .8em;
						}
						@media only screen and (max-width: 768px){
							display: block;
							float: none;
							width: 100%;
							max-width: 30rem;
						}
					}
					.eyecatch{
						width: calc(100% * 4 / 9 - 1em);
						float: left;
						padding-left: 2em;
						img{
							width: 100%;
						}
						@media only screen and (max-width: 768px){
							width: 100%;
							max-width: 320px;
							margin-top: 1rem;
							float: none;
							display: block;
							padding-left: 0;
							opacity: .7;
						}
					}
				}
			}
		}
		.pagenation{
			margin-left: 14rem;
			border-top: 1px solid rgba($grey, .8);
			font-family: 'Suez One', serif;
			padding-top: 1em;
			padding-bottom: 6em;
			@media only screen and (min-width: 1580px){
				margin-left: 18rem;
			}
			@media only screen and (max-width: 768px){
				margin-left: 2rem;
			}
			span{
				margin-right: 1rem;
				color: $grey;
				cursor: pointer;
			}
			span:hover{
				text-decoration: underline;
			}
			span.current{
				color: rgba($grey, .3);
				text-decoration: underline;
			}
		}
	}//end main
	.side-bg{
		content: '';
		display: block;
		position: absolute;
		top:0;bottom:0;left:0;
		right: calc(100% - 12rem);
		background-color: $offWhite;
		z-index: -10;
		@media only screen and (min-width: 1580px){
			right: calc(100% - 16rem);
		}
		@media only screen and (max-width: 768px){
			right: calc(100% - 7rem);
			opacity: .8;
		}
	}
}

#news_single{
	background-color: white;
	#page-header{
		height: 100vh;
		box-sizing: border-box;
		h2{
			padding-top: 60vh!important;
			padding-bottom: .1em!important;
		}
		h3{
			font-size: 1.6rem;
			color:$grey;
			font-weight: 500;
			padding-bottom: 8em;
			line-height: 1.4em;
			position: relative;
			z-index: 10;
		}
	}
	.sub_clm{
		padding-top: 4rem;
		padding-bottom: 8em;
		position: relative;
		time{
			position: absolute;
			z-index: 10;
			top: -3rem;
			left: 2rem;
			display: inline-block;
			font-size: .9em;
			font-weight: 500;
			letter-spacing: .1em;
			color: $grey;
			@media only screen and (max-width: 768px){
				left:1rem;
			}
		}
		//general style
		h1, h2, h3, h4, h5, h6{
			font-weight: 500;
			line-height: 1.6em;
			margin: .8em 0 .4em;
		}
		h1{font-size: 2.8em;}
		h2{font-size: 2.6em;}
		h3{font-size: 2.4em;}
		h4{font-size: 2.2em;}
		h5{font-size: 2em;}
		h6{font-size: 1.8em;}
		ul, ol{
			margin: 1em 0;
			padding-left: 1.4em;
		}
		p{
			max-width: 40em;
		}
		img{
			max-width: 100%;
			height: auto;
		}
		a{
			color: $orange;
			text-decoration: none;
		}
		a:hover{
			text-decoration: underline;
		}
		//youtube
		.wp-block-embed-youtube{
			position: relative;
			padding-bottom:56.25%;
			padding-top:30px;
			height: 0;
			overflow:hidden;
			iframe{
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				height: 100%;
			}
		}
		//gallery
		ul.wp-block-gallery{
			list-style: none;
			padding-left: 0;
			margin: 4em auto;
			@supports (grid-area:auto){
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				grid-gap: 2em;
				li{
					grid-column: span 1;
				}
				@media only screen and (max-width: 768px){
					li{
						grid-column: span 3;
					}
				}
			}
			@supports not (grid-area:auto){
				display:inline-block;
				max-width: 768px;
				li{
					margin-bottom: 2em;
				}
			}
			li{
				figure{
					text-align: center;
				}
				figcaption{
					font-size: .8em;
					opacity: .9;
					letter-spacing: .1em;
				}
			}
		}

	}
	.nav_ui{
		display: table;
		width: 100%;
		margin: 4rem auto 0;
		border-top: 1px solid rgba($grey, .3);
		padding: 2rem 8rem 0;
		box-sizing: border-box;
		@media only screen and (max-width: 1024px){
			padding: 2rem 1rem 0;
		}
		@media only screen and (max-width: 768px){
			padding: 2rem 0 0;
		}
		.next, .prev, .index{
			display: table-cell;
			width: 9rem;
			background: none;
			color: $navy;
			font-size: .9em;
			font-weight: 500;
			letter-spacing: .1em;
			border: none;
			line-height: 2rem;
			cursor: pointer;
			@media only screen and (max-width: 768px){
				width: 2.6rem;
				background-color: rgba($navy, .2);
				span{
					opacity: 0;
				}
				height: 35px;
			}

		}
		.next, .prev{
			position: relative;
			svg{
				position: absolute;
				bottom: .7rem;
				width: .6rem;
				@media only screen and (max-width: 768px){
					bottom: .7rem;
				}
			}
		}
		.prev{
			svg{
				left: 1rem;
			}
		}
		.next{
			svg{
				right: 1rem;
			}
		}
		.index{
			width: calc(100% - 18rem);
			background: $navy;
			color: white;
			@media only screen and (max-width: 768px){
				width: calc(100% - 5.2rem);
			}
		}
	}
}


