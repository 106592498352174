@import "./css_reset.scss";
@import "./config.scss";
img {
  -webkit-touch-callout:none; 
}
.clearfix:after, .clearfix:before{
	content: '';
	display: table;
}
.clearfix:after{
	clear: both;
}

body{
	background: $offWhite;
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 300;
	line-height: 1.8em;
}
::-webkit-scrollbar { 
    display: none; 
}

img{
	max-width: 100%;
	height: auto;
}

.main_clm{
	padding-left: 2rem;
	padding-right: 2rem;
	@media only screen and (min-width: 1580px){
		padding-left: 12rem;
		padding-right: 12rem;
	}
	@media only screen and (max-width: 768px){
		padding-left: 1rem;
		padding-right: 1rem;
	}
}
.sub_clm{
	padding-left: 8rem;
	padding-right: 8rem;
	@media only screen and (min-width: 1580px){
		padding-left: 14rem;
		padding-right: 14rem;
	}
	@media only screen and (max-width: 768px){
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

#content{
	opacity: 0;
	transition: opacity .2s ease-in;
	padding-right: $headerWidth;
}
#content.ready{
	opacity: 1;
	transition: opacity .3s ease-out;
}


//typography
body{
	font-size: 15px;
	@media only screen and (max-width: 1024px){
		font-size: 14px;
	}
	@media only screen and (max-width: 768px){
		font-size: 11px;
	}
}
.bold{
	font-weight: 500;
}
.en{
	font-family: 'Suez One', serif;
}

h2.en{
	font-size: 3em;
	letter-spacing: .1em;
	text-transform: uppercase;
	line-height: 1.4em;
	color: $grey;
	@media only screen and (max-width: 768px){
		font-size: 2.4em;
	}
}
h3.en{
	font-size: 2.8em;
	letter-spacing: .1em;
	text-transform: uppercase;
	line-height: 1.4em;
	@media only screen and (max-width: 768px){
		font-size: 2.2em;
	}
}

.en.m{
	font-size: 1.8em;
}

p{
	max-width: 40em;
	margin-bottom: 1em;
}




