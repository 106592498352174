@import "./config.scss";

#logo{
	position: fixed;
	left: 2rem;
	top: 1.4rem;
	z-index: 9999;
	width: 124px;
	cursor: pointer;
	@media only screen and (max-width: 768px){
		top: 1rem;
		left: 1rem;
	}
}
#hamburger{
	cursor: pointer;
	position: fixed;
	z-index: 9999;
	right: 0;
	width: $headerWidth;
	height: 38px;
	span{
		position: absolute;
		right: 6px;
		top: 12px;
		z-index: 9999;
		display: block;
		width: 24px;
		height: 2px;
		background: $navy;
	}
	span:nth-of-type(2){
		top: 18px;
	}
	span:nth-of-type(3){
		top:24px;
	}
}

//common
#header{
	background: white;
	color: $navy;
	box-shadow: -2px 0px 5px 0px rgba(0,0,0,0.25);
	ul{list-style: none;}
	nav{
		padding-top: 20vh;
		>span{
			font-size: 1em;
			text-align: center;
			display: block;
			color: $orange;
			margin-bottom: 1.7em;
			letter-spacing: .3em;
		}
		ul.menu{
			font-size: 2.4em;
			text-align: center;
			li{
				display: block;
				padding-bottom: 1em;
				cursor: pointer;
			}
		}

	}
	ul.sns{
		position: absolute;
		bottom: 0;
		left: 8px;
		li{
			display: block;
			margin-bottom: .4em;
			cursor: pointer;
			a{
				color: $navy;
				text-decoration: none;
				font-size: 12px;
				position: relative;
				.icon, .base{
					padding-right: 12px;
					font-size: 20px;
				}
				span{
					position: absolute;
					left: 28px;
					bottom: 0;
				}
			}
		}
	}
	img.base{
		width: 20px;
	}
}

@media only screen and (min-width: 769px){
	#header.open{
		right: 0;
		transition: right .3s ease-out;
	}
	#header{
		transition: right .2s ease-out;
		z-index: 9998;
		position: fixed;
		top:0; bottom:0;
		right: $headerWidth - 480px;
		width: 480px;
		.follow_me{
			font-family: 'Noto Sans JP', sans-serif;
			font-weight: 500;
			position: absolute;
			left: .9em;
			bottom:190px;
			color: $grey;
			line-height: 1.6em;
			span{
				width: 100%;
				display: block;
				text-align: center;
				font-size: 7px;
			}
			span:nth-of-type(6){
				padding-bottom: 1em;
			}
		}

	}
	
}
@media only screen and (max-width: 768px){
	#header.open{
		right: 0;
		transition: right .3s ease-out;
	}
	#header{
		transition: right .2s ease-out;
		width: 100vw;
		position: fixed;
		z-index: 9998;
		top:0;
		bottom:0;
		right:calc(36px - 100vw);
		overflow-y: scroll;
		.follow_me{
			display: none;
		}
		ul.sns{
			text-align: center;
			position: relative;
			margin-top: 2em;
			li{
				display: inline-block;
				a{
					.icon{
						font-size: 28px;
					}
					.base{
						width: 28px;
					}
					span{
						display: none;
					}
				}
			}
		}

	}
}




