@import "./config.scss";
#top{
	.tagline{
		position: absolute;
		left: 0;
		right: 0;
		top: 8rem;
		text-align: center;
		font-size: 10px;
		color: $grey;
		letter-spacing: 18px;
		@media only screen and (max-width: 768px){
			text-align: left;
			padding-left: 1rem;
			top: 8.7rem;
			font-size: 8px;
			letter-spacing: 8px;
		}
	}
	#topVideoContainer{
		position: relative;
		box-sizing: border-box;
		display: block;
		padding: 10rem 0;
		height: 100vh;
		min-height: 60vw;
		max-height: 840px;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+40,acdee5+100&0+40,1+100,0.4+100 */
		background: -moz-linear-gradient(top, rgba(255,255,255,0) 40%, rgba(172,222,229,0.4) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(255,255,255,0) 40%,rgba(172,222,229,0.4) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(255,255,255,0) 40%,rgba(172,222,229,0.4) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#66acdee5',GradientType=0 ); /* IE6-9 */
		.p5canvas{
			width: calc(100% + 36px);
			margin-right: -$headerWidth;
			min-height: 100vh;
			z-index: -1;
			background-color: white;
		}
		.altVid,
		video{
			margin: auto;
			width: 70vw;
			opacity: 1;
			max-width: 820px;
			display: block;
			@media only screen and (max-width: 768px){
				width: calc(100vw - 2rem);
			}
		}
		.latest_news{
			position: absolute;
			bottom: .5rem;
			left: 2rem;
			@media only screen and (max-width:768px){
				left: 1rem;
			}
			h4{
				color: $orange;
				font-size: 1.4em;
				letter-spacing: .1em;
			}
			p{
				font-size: 1em;
				time{
					font-size: .7em;
					padding-right: 1em;
				}
				cursor: pointer;
			}
			p:hover{
				span{
					text-decoration: underline;
				}
				time{
					text-decoration: none;
				}
			}
		}
	}//end #topVideoContainer
	.about{
		padding-top: 8em;
		padding-bottom: 10em;
		.right{
			max-width: 32rem;
			p{
				line-height: 2em;
			}
			.more{
				margin-top: 1em;
			}
		}
		@media only screen and (min-width: 1025px){
			position: relative;
			.left{
				float: left;
				max-width: calc(100% * 7/12 - 1em);
				@media only screen and (max-width: 1260px){
					max-width: 100% * 6/12;					
				}
			}
			.right{
				float: left;
				padding-top: 8rem;
				padding-left: 2rem;
				p{
					max-width: 30em;
				}
				@media only screen and (max-width: 1260px){
					padding-top: 4rem;
					p{
						max-width: 28em;
					}
				}
			}
		}
		@media only screen and (max-width: 1024px){
			.left, .right{
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
			.left{
				text-align: center;
				max-width: 32rem;
				margin-bottom: 1rem;
			}
		}
	}//end .about

	.photo{
		background-color: white;
		h3{
			text-align: center;
			padding-top: 2.4em;
			color:$navy;
			line-height: 1em;
			span{
				display: block;
				font-size: .9rem;
				letter-spacing: .2em;
				font-family: 'Noto Sans JP', sans-serif;
				margin-bottom: 3em;
			}
		}
		@media only screen and (min-width: 1025px){
			>ul{
				display: table;
				width: 100%;
				min-height: 480px;
				height: 70vh;
				li{
					box-sizing: border-box;
					display: table-cell;
					height: 70vh;
					text-align: center;
					width: 25%;
					transition: width .2s;
					position: relative;
					background-position: center center;
					background-size: cover;
					.bg{
						position: absolute;
						left: 0; right: 0; top:0; bottom:0;
						background-color: rgba(black, 0);
						transition:background-color, .2s;
						z-index: 0;
						height: 70vh;
					}
					.content{
						z-index: 10;
						position: relative;
						opacity: 0;
						transition: opacity .1s;
						h3, p, button{
							color: white;
						}
						h3{
							padding-top: 20vh;
							span{
								font-family: 'Noto Sans JP', sans-serif;
								font-weight: 300;
								display: block;
								font-size: .9rem;
								font-weight: 500;
								letter-spacing: .2em;
								margin-bottom: 2rem;
							}
						}
						p{
							margin-left: auto;
							margin-right: auto;
							max-width: 20rem;
							padding: 0 2rem 2rem;
							text-align: center;
						}
					}

				}
			}
			>ul:hover{
				li{
					width: 20%;
				}
				li:hover{
					transition: width .2s;
					width: 40%;
					.bg{
						background-color: rgba(black, .7);
						transition:background-color, .2s;
					}
					.content{
						opacity: 1;
						transition: opacity .4s ease-out .2s;
					}
				}
			}
		}
		@media only screen and (max-width: 1024px){
			>ul{
				li{
					position: relative;
					padding-bottom: 4rem;
					background-size: cover;
					background-position: center center;
					.content{
						position: relative;
						z-index: 10;
						h3, p, button{
							color: white;
						}
						h3{
							span{
								margin-bottom: 2rem;
							}
						}
						p{
							max-width: 20rem;
							margin: 2rem auto;
							padding: 0 1rem;
						}
						button{
							text-align: center;
							display: block;
							margin:auto;
						}
					}
					.bg{
						position: absolute;
						top:0;right:0;bottom:0;left:0;
						background: rgba(black, .7);
						height: auto;
					}
				}
			}
		}
		@media only screen and (max-width: 768px){
			h3{
				font-size: 2.6em;
			}
		}
	}//end .photo
	.video{
		padding-top: 10em;
		>div{
			margin-bottom: 4rem;
			video{
				width: calc(100% * 7/12 - 3rem);
				float: left;
				@media only screen and (max-width: 768px){
					float: none;
					width: 100%;
				}
			}
			.right{
				box-sizing: border-box;
				width: calc(100% * 4/12);
				float: left;
				padding-left: 2rem;
				@media only screen and (max-width: 1024px){
					width: calc(100% * 5/12);
				}
				h3{
					padding-top: 1.4em;
					color:$yellow;
					line-height: 1em;
					margin-left: -7rem;
					z-index: 20;
					position: relative;
					span{
						color: $grey;
						padding-left: 7rem;
						display: block;
						font-size: .9rem;
						letter-spacing: .2em;
						font-family: 'Noto Sans JP', sans-serif;
						margin-bottom: 1rem;
					}
				}
				@media only screen and (max-width: 768px){
					float: none;
					width: 100%;
					max-width: 20rem;
					margin-left: auto;
					margin-right: auto;
					padding-left: 0;
					h3{
						margin-left: 0;
						padding-top: 2rem;
						font-size: 2.6em;
						text-align: center;
						span{
							padding-left: 0;
						}
					}
				}
			}//end .right
		}//end >div
		div.videoList{
			position: relative;
			margin-bottom: 0;
			padding-bottom: 8em;
			h4{
				position: absolute;
				left: 0;
				right: 2rem;
				top: 4rem;
				padding-top: 1rem;
				padding-bottom: 1rem;
				background-color: $offWhite;
				padding-right: calc(100% *10/12 - 4rem);;
				text-align: right;
				font-size: 1em;
				color: $grey;
				letter-spacing: .1em;
				text-transform: uppercase;
				@media only screen and (min-width: 1580px){
					right: 12rem;
					padding-right: calc(100% *10/12 - 20rem);;
				}
			}
			ul.videoList{
				display: table;
				padding-left: calc(100% * 2/12 + 2rem);
				width: 100%;
				box-sizing: border-box;
				li{
					display: table-cell;
					width: calc(100% / 3);
					box-sizing: border-box;
					padding-right: 1rem;
					.thumbnail{
						height: 180px;
					}
				}
				li:last-of-type{
					padding-right: 0;
				}
			}
			@media only screen and (max-width: 1024px){
				h4{
					position: relative;;
					top:auto;left:auto;right:auto;
					text-align: center;
					background: none;
					letter-spacing: .2em;
					padding-right: 0;
					font-size: 1.1rem;
					margin-bottom: 1rem;
				}
				ul.videoList{
					padding-left: 0;
				}
			}
			@media only screen and (max-width: 768px){
				ul.videoList{
					display: block;
					li{
						padding-right: 0;
						display: block;
						width: 100%;
						max-width: 480px;
						margin: 0 auto 2rem;
					}
				}
			}
		}
	}//end .video
}//end #top

