@import './config.scss';

#psg:before, #psg:after{
	content: '';
	display: table;
}
#psg:after{
	clear: both;
}
.pswp{
	z-index: 9999;
}
#psg{
	//IE 11
	@media all and (-ms-high-contrast:none){
	  *::-ms-backdrop, .pswp-thumbnail {
	  	max-width:580px!important;
	  	float: none!important;
	  	height: auto!important;
	  	width: 100%!important;
	  }
	}
	padding-top: 2em;
	.pswp-thumbnails{
		cursor: pointer;
		.pswp-thumbnail:hover{
			opacity: .9;
		}
		.pswp-thumbnail{
			transition: all .3s;
			height: 18vw;
			@media only screen and (max-width: 768px){
				height: 22vw;
			}
			@media only screen and (max-width: 768px){
				height: 18vw;
			}
			@supports not (grid-area:auto){
				width: 100%;
				max-width: 580px;
				height: auto;
				float: none;
				overflow: hidden;
				box-sizing: border-box;
				padding: 4px;
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover
			}
		}//end .pswpp-thumbnail
	}//end .pswpp-thumbnails
	@supports (grid-area:auto){
		.pswp-thumbnails{
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 8px;
			@media only screen and (max-width: 768px){
				grid-template-columns: repeat(2, 1fr);				
			}
			.pswp-thumbnail{
				grid-column:span 1;
				grid-row: span 1;

			}
		}
	}
}