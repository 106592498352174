@import './config.scss';
.videoPop{
	position: fixed;
	z-index: 9999;
	top:0;right:0;bottom:0;left:0;
	background-color:rgba(black,.9);
	opacity: 0;
	display: none;
	div.content{
		.close{
			font-size: 1.8rem;
			color: white;
			cursor: pointer;
			position: fixed;
			right: 5rem;
			top: 5rem;
			span{
				font-size: .7rem;
				position: absolute;
				right: 2rem;
				top: 1px;
				font-weight: 500;
			}
			@media only screen and (max-width: 768px){
				top: 2rem;
				right: 2rem;
			}
		}
		//vimeo
		.vimeo{
			width: 600px;
			height: 400px;
			margin:auto;
			margin-top: calc((100vh - 400px) / 2);
			position: relative;
			@media only screen and (max-width: 768px){
				width: 300px;
				height: 200px;
				margin-top: calc((100vh - 200px) / 2);
			}
			.vimeo-image{
				position: absolute;
				background-size:cover;
				background-position: center;
				max-height: 400px;
				margin:auto;
				.vimeo-play-button{
					cursor: pointer;
					transition: opacity .3s;
					display: block;
					margin:auto;
					position: absolute;
					top:0;right:0;bottom:0;left:0;
					border: none;
					border-radius: 12px;
			    background: none;
			    background: rgba(white, .8);
			    width: 5rem;
			    height: 3.8rem;
			    color: white;
					padding: 1rem;
					box-sizing: border-box;
					svg{
						width: 30px;
					}
				}
				.vimeo-play-button:hover{
					opacity: .8;
				}
			}
			.vimeo-embed{
				iframe{
					width: 100%;
					height: 100%;
				}
			}
		}
		//youtube
		>div >span{
			iframe{
				display: block;
				position: absolute;
				left:0;
				right: 0;
				bottom:0;
				top:0; 
				margin:auto;
			}
		}

		//closer
		.closer{
			position: absolute;
			top:0;right:0;bottom:0;left:0;
			background-color: none;
		}
	}
}
.videoList{
	list-style: none;
	li{
		h3{
			font-size: 8px;
			color: $grey;
			text-align: center;
			padding-top: .8em;
			font-family: 'Noto Sans JP', sans-serif;
			font-weight: 500;
		}
		>div{
			position: relative;
			.hover{
				position: absolute;
				left:0;top:0;right:0;bottom:0;
				background-color: rgba(black, .9);
				color: white;
				font-size: 2em;
				text-align: center;
				color: rgba(white, .5);
				opacity: 0;
				transition: all .3s;
				cursor: pointer;
				svg{
					position: absolute;
					left:0;top:0;right:0;bottom:0;
					display: block;
					margin:auto;
				}
			}
			.hover:hover{
				color: rgba(white, .7);
				opacity: 1;
			}
		}
		.thumbnail{
			background-position: center;
			background-size:cover;
			min-width: 100px;
			min-height: 60px;
		}
	}
}