@import './config.scss';

#contact{
	main{
		padding-top: 4rem;
		padding-bottom: 4rem;
		background-color: white;
		h3{
			color:$navy;
			text-transform: none;
			margin-bottom: 1rem;
		}
		p{
			margin-bottom: 1.2rem;
		}
		a{
			color: $orange;
			font-size: 1rem;
			font-weight: 500;
		}
	}
	#draft{
		padding-top: 4rem;
		padding-bottom: 4rem;
		input, textarea{
			box-sizing: border-box;
			width: 100%;
			display: block;
			-webkit-appearance: none;
			border-radius: 4px;
			padding: .5rem;
			border: none;
			background: #fff;
			margin-bottom: 1rem;
			font-size: .7rem;
		}
		.left{
			float: left;
			width: calc(50% - 1rem);
		}
		.right{
			float: right;
			width: calc(50% - 1rem);
			height: calc(1.7rem * 4 + 3rem + .5rem);
			textarea{
				height: 100%;
			}
		}
		input[type="submit"]{
			width:40%;
			margin:2rem auto 0;
			background-color: $navy;
			color: white;
			font-size: 1rem;
			font-weight: 500;
		}

		//error
		p{
			font-size: 8px;
			margin-top: -1rem;
			padding-left: .5rem;
			color: $orange;
		}

		@media only screen and (max-width: 1024px){
			.left, .right{
				float: none;
				width: 100%;
			}
		}
	}
	aside{
		padding-left: 8rem;
		padding-right: 8rem;
		@media only screen and (min-width: 1580px){
			padding-left: 14rem;
			padding-right: 14rem;
		}
		@media only screen and (max-width: 768px){
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}
}// close #contact