@import './config.scss';

#footer{
	font-size: .9em;
	background-color: $navy;
	nav{
		display: inline-block;
		ul{
			display: inline-block;
			padding-left: 1em;
			li{
				cursor: pointer;
				color: $offWhite;
				padding: 8px 1em;
				display: inline-block;
			}
		}
	}
	p{
		font-size: .8em;
		padding-top: 8px;
		color: $blue;
		display: inline-block;
		float: right;
		padding-right: 2em;
		margin-bottom: 0;
	}
	@media only screen and (max-width: 768px){
		nav{
			display: none;
		}
		p{
			float: none;
			display: block;
			text-align: left;
			padding: 4px;
			padding-left: 1rem;
		}
	}
}