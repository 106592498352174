@import './config.scss';

#pg{
	background-color: white;
	.container{
		width: 100%;
		position: relative;
		nav{
			background-color: $offWhite;
			width: calc(100% * 2/12);
			padding-left: 2rem;
			box-sizing: border-box;
			position: absolute;
			left: 0;
			top:0;
			bottom: 0;
			@media only screen and (max-width: 768px){
				width: 86px;
				padding-right: 2em;
				padding-left: 1em;
			}
			ul{
				padding-top: 7em;
				list-style: none;
				li{
					color: $grey;
					line-height: 1.4em;
					margin-bottom: 1.4em;
					cursor: pointer;
					@media only screen and (max-width: 768px){
						font-size: 8px;
					}
				}
				li.current{
					opacity: .4;
				}
				li.contact{
					margin-top: 2.4em;
					padding-right: 1em;
					@media only screen and (max-width: 1024px){
						font-size: 7px;
						br{
							display: none;
						}
						svg{
							display: none;
						}
					}
					p{
						margin-top: .5em;
						font-family: 'Noto Sans JP', sans-serif;
						font-size: 6px;
						line-height: 1.6em;
						letter-spacing: 0;
					}
					@media only screen and (max-width: 768px){
						svg{display: none;}
						button{
							letter-spacing: 0;
							font-size: 1.2em;
						}
						p{
							display: none;
						}
					}
				}
			}
		}
		.sports{
			#psg{
				.pswp-thumbnails{
					.pswp-thumbnail:nth-of-type(2),
					.pswp-thumbnail:nth-of-type(4){
						@supports (grid-area:auto){
							@media only screen and (min-width:769px){
								grid-column:span 2;
								grid-row: span 2;
								height: 36.7vw;	
							}
						}
					}
				}
			}
		}
		.tour{
			#psg{
				.pswp-thumbnails{
					@supports (grid-area:auto){
						@media only screen and (min-width:769px){
							.pswp-thumbnail:first-of-type{
								grid-column:span 2;
								grid-row: span 2;
								height: 36.7vw;	
							}
							.pswp-thumbnail:nth-of-type(6){
								grid-row: span 2;
								height: 36.7vw
							}
						}
					}
				}
			}
		}
		.landscape{
			#psg{
				.pswp-thumbnails{
					@supports (grid-area:auto){
						@media only screen and (min-width:769px){
							.pswp-thumbnail:nth-of-type(3),
							.pswp-thumbnail:nth-of-type(4){
								grid-row: span 2;
								height: 36.7vw;
							}
							.pswp-thumbnail:nth-of-type(6),
							.pswp-thumbnail:nth-of-type(8){
								grid-row: span 2;
								grid-column: span 2;
								height: 36.7vw;
							}
							.pswp-thumbnail:nth-of-type(12){
								grid-column:span 2;
							}
						}
					}
				}
			}
		}
		>div{
			section, aside{
				padding-left: 2rem;
				padding-right: 2rem;
				padding-bottom: 6em;
				margin-left: calc(100% * 2/12);
				@media only screen and (min-width: 1580px){
					padding-right: 12rem;
					padding-left: 3rem;
					margin-left: calc(100% * 2/12 + 132px;);
				}
				@media only screen and (max-width: 768px){
					padding-left: 1em;
					padding-right: 1em;
					margin-left: 86px;
				}
			}
			section{
				padding-top: 6em;
				h3{
					color: $navy;
					margin-bottom: .8em;
				}
				p{
					a{
						color: black;
					}
					a:hover{
						opacity: .8;
					}
				}
				p.english{
					line-height: 1.8em;
					margin-bottom: 2em;
				}
			}
		}


		@media only screen and (min-width: 1580px){
			nav{
				padding-left: 12rem;
				width: calc(100% * 3/12);
			}
		}
	}// end .container
}