@import './config.scss';

#vg{
	.typo_bg{
		background-color: white;
		padding-top: 3em;
		padding-bottom: 3em;
		position: relative;
		p{
			position: relative;
			z-index: 10;
		}
		span{
			letter-spacing: -.05em;
			color: rgba($offWhite, .3);
			font-size: 10.4vw;
			line-height: 2em;
			position: absolute;
			top:0;right:0;bottom:0;left:0;
			text-align: center;
			display: block;
			line-height: 11rem;
			@media only screen and (max-width: 768px){
				font-size: 9vw;
				line-height: 0;
				top:auto;
				bottom: 1.4rem;
				text-align: left;
				left: 1rem;
			}
		}
	}
	ul.cm ,ul.sports{
		padding-bottom: 6em;
		>h3{
			text-align: center;
			color: $navy;
			padding: 4em 0 2em;
		}
		li{
			width: calc(50% - 1rem);
			float: left;
			padding-bottom: 2em;
			.thumbnail{
				height: 20vw;
			}
		}
		li:nth-of-type(2n){
			float: right;
		}
		@media only screen and (max-width: 768px){
			li{
				float: none;
				width: 100%;
				max-width: 480px;
				margin-left: auto;
				margin-right: auto;
				.thumbnail{
					height: 180px;
				}
			}
			li:nth-of-type(2n){
				float: none;
			}
		}
	}
	ul.cm{

	}

	ul.sports{
		background-color: white;
	}
	aside{
		padding-left: 8rem;
		padding-right: 8rem;
		@media only screen and (min-width: 1580px){
			padding-left: 14rem;
			padding-right: 14rem;
		}
		@media only screen and (max-width: 768px){
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}
}