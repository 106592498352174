@import './config.scss';
aside.page{
	padding-top: 4em;
	padding-bottom: 8em;
	font-size: .8rem;
	line-height: 1.8em;
	color: $grey;
	@media only screen and (min-width: 1025px){
		.left{
			width: 30%;
			float: left;
		}
		.right{
			padding-left: calc(30% + 2rem);
		}
	}
	@media only screen and (max-width: 1024px){
		.left{
			margin-bottom: 2rem;
		}
	}
	@media only screen and (max-width: 768px){
		br{
			display: none;
		}
	}
	h3{
		color: $navy;
		span{
			font-family: 'Noto Sans JP', sans-serif;
			font-size: .8rem;
			letter-spacing: .2em;
			font-weight: 500;
			display: block;
			color: $grey;

		}
	}
	h4{
		font-weight: 500;
		color: black;
		font-size: .8rem;
		letter-spacing: .1em;
		margin-top: 3em;
		margin-bottom: .4em;
	}
	h4:first-of-type{
		margin-top: 0;
	}
	ul, ol{
		li{
			margin-bottom: 1rem;
			list-style: none;
		}
	}
	dl.example{
		font-size: .9em;
		padding: 1em 2em 2em;
		background-color: rgba($offWhite, .5);

	}
	dl{
		>div{
			display: table;
			width: 100%;
		}
		.total{
			font-weight: 400;
		}
		.sub{
			dt, dd{
				border-style: dashed;
			}
		}
		.option{
			>div{
				width: 100%;
				display: table;
			}
			h5{
				font-weight: 400;
				padding-top: 1em;
				padding-bottom: .8em;
				border-bottom: 1px dashed rgba($grey, .4);
				letter-spacing: .1em;
			}
		}
		.option dt,
		.option dd,
		dt, dd{
			border-bottom: 1px solid rgba($grey, .3);
			display: table-cell;
			padding:.8em 0;
			box-sizing: border-box;

		}
		dt{
			width: 40%;
			@media onlyscreen and (max-width: 768px){
				width: 50%;
			}
		}
		dd{
			width: 60%;
			@media onlyscreen and (max-width: 768px){
				width: 50%;
			}
			span{
				display: block;
				font-size: .8em;
			}
		}
	}
	p.caution{
		font-size: .8em;
		line-height: 1.6em;
		margin-top: .5em;
	}
	button.more{
		font-size: 1.6rem;
		margin-top: 4rem;
		@media only screen and (max-width: 1024px){
			display: none;
		}
	}
	p.contact{
		margin-top: 1em;
		font-size: .7rem;
		line-height: 1.8em;
		@media only screen and (max-width: 1024px){
			display: none;
		}
	}
}